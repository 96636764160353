<template>
    <div>
        <vs-table v-if="viewMode == 'compact'" :data="registrations">
            <template slot="thead">
                <th>Type</th>
                <th class="text-center">Pending</th>
                <th class="text-center">Approved</th>
                <th class="text-center">Rejected</th>
            </template>
            <template v-for="(registration, index) in registrations">
                <tr :key="index">
                    <th>
                        <router-link :to="{name: 'entity-registration-manage', params: {registration_type: registration.id}}">
                            {{ registration.name }}
                        </router-link>
                    </th>
                    <template v-if="registration.allow_multiple">
                        <template v-if="registration.registrations && registration.registrations.length">
                            <th class="text-center text-warning">{{ registration.registrations.filter(v => v.status == 'p').length }}</th>
                            <th class="text-center text-success">{{ registration.registrations.filter(v => v.status == 'a').length }}</th>
                            <th class="text-center text-danger">{{ registration.registrations.filter(v => v.status == 'r').length }}</th>
                        </template>
                        <template v-else>
                            <td colspan="3" class="text-center">
                                <strong>Not Registered</strong>
                            </td>
                        </template>
                    </template>
                    <template v-else>
                        <td colspan="3" class="text-center py-2">
                            <template v-if="registration.registrations">
                                <strong v-if="registration.registrations.status == 'a'" class="text-success">
                                    <font-awesome-icon size="1x" :icon="checkIcon" /> Approved
                                </strong>
                                <strong v-else-if="registration.registrations.status == 'p'" class="text-warning">
                                    <font-awesome-icon size="1x" :icon="clockIcon" /> Pending Approval
                                </strong>
                                <strong v-else-if="registration.registrations.status == 'r'" class="text-danger">
                                    <font-awesome-icon size="1x" :icon="xIcon" /> Rejected
                                </strong>
                            </template>
                            <template v-else>
                                <strong>
                                    Not Registered
                                </strong>
                            </template>
                        </td>
                    </template>
                </tr>
            </template>
        </vs-table>
        <vs-row v-else>
            <template v-for="(registration, index) in registrations">
                <vs-col :key="index" vs-lg="4" vs-sm="6" vs-xs="12" class="mb-5">
                    <vx-card class="h-full">
                        <div class="card-heading">
                            <h5 class="mb-1">{{ registration.name }}</h5>
                            <small>{{ registration.description }}</small>
                        </div>
                        <template v-if="registration.allow_multiple">

                            <vs-row class="text-center">
                                <vs-col class="text-success" vs-xs="12" vs-sm="4" vs-lg="4">
                                    <strong>
                                        {{ registration.registrations.filter(v => v.status == 'a').length }} <br>
                                        Approved
                                    </strong>
                                </vs-col>
                                <vs-col class="text-warning" vs-xs="12" vs-sm="4" vs-lg="4">
                                    <strong>
                                        {{ registration.registrations.filter(v => v.status == 'p').length }} <br>
                                        Pending
                                    </strong>
                                </vs-col>
                                <vs-col class="text-danger" vs-xs="12" vs-sm="4" vs-lg="4">
                                    <strong>
                                        {{ registration.registrations.filter(v => v.status == 'r').length }} <br>
                                        Rejected
                                    </strong>
                                </vs-col>
                            </vs-row>

                        </template>
                        <template v-else>

                            <template v-if="registration.registrations">

                                <vs-row class="text-center">
                                    <vs-col>
                                        <strong v-if="registration.registrations.status == 'a'" class="text-success">
                                            <font-awesome-icon size="1x" :icon="checkIcon" /> Approved
                                        </strong>
                                        <strong v-else-if="registration.registrations.status == 'p'" class="text-warning">
                                            <font-awesome-icon size="1x" :icon="clockIcon" /> Pending Approval
                                        </strong>
                                        <strong v-else-if="registration.registrations.status == 'r'" class="text-danger">
                                            <font-awesome-icon size="1x" :icon="xIcon" /> Rejected
                                        </strong>
                                    </vs-col>
                                </vs-row>

                            </template>

                            <template v-else>
                                <vs-row class="text-center">
                                    <vs-col class="text-danger font-bold">
                                        No {{ registration.identifier_label_singular }} Registered
                                    </vs-col>
                                </vs-row>
                            </template>
                        </template>

                        <vs-row class="text-center mt-base">
                            <vs-col>
                                <router-link :to="{name: 'entity-registration-manage', params: {registration_type: registration.id}}">
                                    <vs-button color="primary" type="filled" size="small">
                                        Manage
                                    </vs-button>
                                </router-link>
                            </vs-col>
                        </vs-row>
                    </vx-card>
                </vs-col>
            </template>
        </vs-row>
    </div>
</template>

<script>
import { faTimes, faClock, faCheck } from '@fortawesome/free-solid-svg-icons';

export default {
    props: {
        entity: {
            required: true
        },
        embedded_mode: {
            required: false,
            default: false
        },
        viewMode: {
            type: String,
            default: 'default',
            required: false
        }
    },
    computed: {
        xIcon: () => faTimes,
        clockIcon: () => faClock,
        checkIcon: () => faCheck
    },
    data() {
        return {
            registrations: []
        }
    },
    mounted(){
      this.load_registrations()
    },
    methods: {
      load_registrations() {
        this.loading = true
        this.$vs.loading()

        this.$api(this, 'GET', this.$store.state.apiendpoints.data_entity_registrations, {limit: 50}).then(
          res => {
            this.registrations = res.results
            this.loading = false
            this.$vs.loading.close()
          },
          err => {
            this.$vs.notify({
              time: 2500,
              title: 'Error',
              text: "An unknown error occured while loading data. Please try again.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
            this.loading = false
            this.$vs.loading.close()
            this.$router.push("/")
          }
        )
      }
    }
}
</script>
